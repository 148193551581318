import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/less/main.less'
// 引入echarts-start
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import '@/assets/js/common.js'

// 引入七牛云上传
import * as qiniu from 'qiniu-js'
Vue.prototype.$qiniu = qiniu

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
