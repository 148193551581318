import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'), // 用户token
    menuList: JSON.parse(localStorage.getItem('menuList')), // 菜单列表
  },
  mutations: {},
  actions: {},
  modules: {}
})
