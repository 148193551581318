import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/user/login'),
      meta: { title: '登录'}
    },
    {
      path: '',
      name: '',
      component: () => import('../views/manage'),
      children: [
        {
          path: '/auth',
          name: 'auth',
          component: () => import('../views/auth/auth'),
          meta: { title: '权限管理'}
        },
        {
          path: '/userManage',
          name: 'userManage',
          component: () => import('../views/userManage/userManage'),
          meta: { title: '用户管理'}
        },
        {
          path: '/roleManage',
          name: 'roleManage',
          component: () => import('../views/roleManage/roleManage'),
          meta: { title: '角色管理'}
        },
        {
          path: '/menuManage',
          name: 'menuManage',
          component: () => import('../views/menuManage/menuManage'),
          meta: { title: '菜单管理'}
        },
        {
          path: '/gameManage',
          name: 'gameManage',
          component: () => import('../views/gameManage/gameManage'),
          meta: { title: '游戏管理'}
        },
        {
          path: '/systemSetting',
          name: 'systemSetting',
          component: () => import('../views/systemSetting/systemSetting'),
          meta: { title: '系统设置'}
        },
        {
          path: '/imgManage',
          name: 'imgManage',
          component: () => import('../views/imgManage/imgManage'),
          meta: { title: '轮播图管理'}
        },
        {
          path: '/gameClass',
          name: 'gameClass',
          component: () => import('../views/gameClass/gameClass'),
          meta: { title: '游戏分类'}
        },
        {
          path: '/checkManage',
          name: 'checkManage',
          component: () => import('../views/checkManage/checkManage'),
          meta: { title: '账单列表'}
        },
        {
          path: '/dictionaryManage',
          name: 'dictionaryManage',
          component: () => import('../views/dictionaryManage/dictionaryManage'),
          meta: { title: '字典管理'}
        },
        {
          path: '/APIManage',
          name: 'APIManage',
          component: () => import('../views/APIManage/APIManage'),
          meta: { title: 'API管理'}
        },
        {
          path: '/docManage',
          name: 'docManage',
          component: () => import('../views/docManage/docManage'),
          meta: { title: '文档管理'}
        },
        {
          path: '/test1',
          name: 'test1',
          component: () => import('../views/test1/test1'),
          meta: { title: '测试1'}
        },
        {
          path: '/test2',
          name: 'test2',
          component: () => import('../views/test2/test2'),
          meta: { title: '测试2'}
        },
      ]
    },
  ]
})

// 判断要去的页面是否包含在可访问的菜单列表中
function checkNameExists(name, array) {  
  for (let i = 0; i < array.length; i++) {  
    const item = array[i];  
    if (item.menu_path === name) {  
      return true; // 如果找到匹配的name，则返回true  
    }  
    if (item.children && Array.isArray(item.children)) {  
      // 如果当前对象有children数组，递归检查children  
      const existsInChildren = checkNameExists(name, item.children);  
      if (existsInChildren) {  
        return true; // 如果在children中找到，则返回true  
      }  
    }  
  }  
  return false; // 如果没有找到匹配的name，则返回false  
} 

// 路由拦截器
router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    // 同时存在token和menuList时访问登录页直接进入到主页 否则清除缓存进入登录页
    if (localStorage.getItem('token') && localStorage.getItem('menuList')) {
      router.push({
        name: JSON.parse(localStorage.getItem('menuList'))[0].menu_path
      })
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('menuList')
      next()
    }
  } else {

    // 存在菜单数据时 判断要去的页面是否在权限内
    if(localStorage.getItem('menuList')) {
      // 输出：false时  说明访问的页面不在权限内 
      var exists = checkNameExists(to.name, JSON.parse(localStorage.getItem('menuList')));  
    }
    // 访问其他页面，同时存在token和menuList时并且访问的页面在权限内则进入，否则则跳到登录页
    if (localStorage.getItem('token') && localStorage.getItem('menuList') && exists) {
        next()
    } else {
      router.push({
        name: 'login'
      })
    }
  }
})

export default router