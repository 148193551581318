<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  components: { },
  data () {
    return {
      
    }
  },
  mounted () { },
  methods: {}
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  // min-width: 1200px;
  overflow-x: hidden;
  height: 100%;
  color: #252631;
  background: #f7f7f9;
  font-family: 'PingFangSC-Regular', 'PingFang SC';
  font-size: 14px;
  font-weight: 400;
  display: flex;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 1.0+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
</style>
