import Vue from 'vue'
import moment from 'moment-timezone' // 时区库处理

// 文件大小转换
Vue.filter('byteChange', (v) => {
  if (!v) return ''
  const num = 1024.0 // byte
  if (v < num) return v + ' B'
  if (v < Math.pow(num, 2)) return (v / num).toFixed(2) + ' KiB' // kb
  if (v < Math.pow(num, 3)) return (v / Math.pow(num, 2)).toFixed(2) + ' MiB' // M
  if (v < Math.pow(num, 4)) return (v / Math.pow(num, 3)).toFixed(2) + ' GiB' // G
  return (v / Math.pow(num, 4)).toFixed(2) + ' TiB' // T
})

// 日期转换
Vue.filter('fmtdate', (v) => {
  return moment(v).format('YYYY-MM-DD HH:mm:ss')
})
// 处理树形菜单数据
export function transformData(data) {
  
    // 初始化一个对象，用于存储一级菜单和它们的二级菜单  
    const primaryMenusWithChildren = {};  

    // 遍历菜单项，处理一级菜单和二级菜单  
    data.forEach(menu => {  
        if (menu.parent_id === 0) {  
            // 如果是一级菜单，则初始化它的children数组  
            primaryMenusWithChildren[menu.ID] = {  
                ...menu,  
                children: []  
            };  
        } else {  
            // 查找父级菜单  
            const parentMenuId = menu.parent_id;  
            const parentMenu = primaryMenusWithChildren[parentMenuId];  
            if (parentMenu) {  
                // 如果找到了父级菜单，将当前菜单添加到它的children数组中  
                parentMenu.children.push({ ...menu }); // 使用浅拷贝避免直接修改原始数据  
            } else {  
                // 如果没有找到父级菜单，记录错误并跳过当前菜单项  
                console.error(`找不到ID为${parentMenuId}的父级菜单，跳过菜单项ID: ${menu.ID}`);  
            }  
        }  
    });  

    // 返回整理后的菜单结构，即一级菜单及其子菜单的数组  
    return Object.values(primaryMenusWithChildren);  
}